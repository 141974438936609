import {
  ADD_CATEGORY,
  ALL_CATEGORY,
  CATEGORY_BY_ID,
  CATEGORY_MAPPED,
  DELETE_CATEGORY,
  UPDATE_CATEGORY,
} from "../actions/types.js";
const initialState = {
  allCategory: null,
  categoryDeleted: false,
  categoryById: {},
  categoryUpdated: false,
  categoryCreated: false,
  categoryMapped: {},
};

export default function CategoryReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ALL_CATEGORY:
      return {
        ...state,
        allCategory: payload,
      };
    case CATEGORY_MAPPED:
      return {
        ...state,
        categoryMapped: payload,
      };
    case DELETE_CATEGORY:
      return {
        ...state,
        categoryDeleted: payload,
      };
    case CATEGORY_BY_ID:
      return {
        ...state,
        categoryById: payload,
      };
    case UPDATE_CATEGORY:
      return {
        ...state,
        categoryUpdated: payload,
      };
    case ADD_CATEGORY:
      return {
        ...state,
        categoryCreated: payload,
      };
    default:
      return state;
  }
}
