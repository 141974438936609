import CategoryService from "../services/category.service";
import {
  ADD_CATEGORY,
  ALL_CATEGORY,
  CATEGORY_BY_ID,
  CATEGORY_MAPPED,
  DELETE_CATEGORY,
  UPDATE_CATEGORY
} from "./types";

export const getAllCategory = (query) => (dispatch) => {
  return CategoryService.listCategory(query).then(
    (data) => {
      dispatch({
        type: ALL_CATEGORY,
        payload: data,
      });
      return Promise.resolve(data);
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};
export const getCategoryMapped = () => (dispatch) => {
  return CategoryService.getCategoryMapped().then(
    (data) => {
      dispatch({
        type: CATEGORY_MAPPED,
        payload: data,
      });
      return Promise.resolve(data);
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};
export const deleteCategory = (id, flag) => (dispatch) => {
  return CategoryService.deleteCategory(id).then(
    (data) => {
      dispatch({
        type: DELETE_CATEGORY,
        payload: !flag,
      });
      return Promise.resolve(data);
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};
export const getCategoryById = (id) => (dispatch) => {
  return CategoryService.getCategoryById(id).then(
    (data) => {
      dispatch({
        type: CATEGORY_BY_ID,
        payload: data,
      });
      return Promise.resolve(data);
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};
export const changeFlag = (flag) => (dispatch) => {
  dispatch({
    type: UPDATE_CATEGORY,
    payload: !flag,
  });
};
export const updateCategory = (body, id) => (dispatch) => {
  return CategoryService.updateCategory(body, id).then(
    (data) => {
      dispatch({
        type: CATEGORY_BY_ID,
        payload: data,
      });
      return Promise.resolve(data);
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};
export const createCategory = (body, flag) => (dispatch) => {
  return CategoryService.createCategory(body).then(
    (data) => {
      dispatch({
        type: ADD_CATEGORY,
        payload: !flag,
      });
      return Promise.resolve(data);
    },
    (error) => {
      dispatch({
        type: ADD_CATEGORY,
        payload: !flag,
      });
      return Promise.reject(error);
    }
  );
};
