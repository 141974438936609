import { ENDPOINTS } from "../../constantsAPI";
import instance from "./interceptor";
const login = async (body) => {
  return instance.post(ENDPOINTS.LOGIN, body).then((response) => {
    return response?.data;
  });
};

const logout = async () => {
  return instance.post(ENDPOINTS.LOGOUT).then((response) => {
    localStorage.removeItem("user");
    return response.data;
  });
};

const updateUserData = async (body) => {
  return instance.patch(ENDPOINTS.AUTH, body).then((response) => {
    return response?.data;
  });
};

const forgetPassword = async (body) => {
  return instance.post(ENDPOINTS.FORGET_PASSWORD, body).then((response) => {
    return response?.data;
  });
};
const verifyOTP = async (body) => {
  return instance.post(ENDPOINTS.VERIFY_OTP, body).then((response) => {
    return response?.data;
  });
};
const resetPassword = async (body) => {
  return instance.post(ENDPOINTS.RESET_PASSWORD, body).then((response) => {
    return response?.data;
  });
};

const services = {
  login,
  logout,
  updateUserData,
  forgetPassword,
  resetPassword,
  verifyOTP
};

export default services;
