import { NAVIGATE } from '../actions/types';

const initialState = {
  currentPath: '/',
};

export default function navigationReducer(state = initialState, action) {
  switch (action.type) {
    case NAVIGATE:
      return {
        ...state,
        currentPath: action.payload,
      };
    default:
      return state;
  }
}
