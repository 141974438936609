import { combineReducers } from "redux";
import aboutUs from "./aboutUs";
import auth from "./auth";
import awards from "./awards";
import bts from "./bts";
import category from "./category";
import contactUs from "./contactUs";
import message from "./message";
import navigation from "./navigation";
import projects from "./projects";
import team from "./team";

export default combineReducers({
  auth,
  message,
  navigation,
  projects,
  category,
  bts,
  contactUs,
  aboutUs,
  awards,
  team,
  // userdata,
  // search,
  // utilities,
  // section
});
