import {
  IS_TOKEN_EXPIRED,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  SET_USER_DATA,
  SET_USER_TOKEN,
} from "../actions/types.js";

const initialState = { isLoggedIn: false, user: null, isTokenExpired: true };

function authReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload,
        isTokenExpired: false,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        isTokenExpired: false,
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        isTokenExpired: true,
      };
    case IS_TOKEN_EXPIRED:
      return {
        ...state,
        isTokenExpired: payload,
      };
    case SET_USER_TOKEN:
      return {
        ...state,
        user: {
          ...state.user,
          token: payload.token,
          refreshToken: payload.refreshToken,
        },
        isTokenExpired: false,
      };
    case SET_USER_DATA:
      return {
        ...state,
        user: {
          ...state.user,
          user:payload
        },
      };
    default:
      return state;
  }
}
export default authReducer;
