import { isExpired } from "react-jwt";
import { store } from "../index";
import AuthService from "../services/auth";
import {
  IS_TOKEN_EXPIRED,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  SET_MESSAGE,
  SET_USER_DATA,
} from "./types";

export const login = (body) => (dispatch) => {
  return AuthService.login(body).then(
    (data) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: data,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: "",
      });
      dispatch({
        type: IS_TOKEN_EXPIRED,
      });
      return Promise.resolve(data);
    },
    (error) => {
      dispatch({
        type: LOGIN_FAIL,
      });
      return Promise.reject(error);
    }
  );
};

export const logout = () => (dispatch) => {
  return AuthService.logout().then(
    (data) => {
      dispatch({
        type: LOGOUT,
      });
      return Promise.resolve(data);
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export const checkTokenExpired = () => (dispatch) => {
  const token = store.getState().auth?.user?.token;
  const isAccessTokenExpired = isExpired(token ?? "");

  dispatch({
    type: IS_TOKEN_EXPIRED,
    payload: isAccessTokenExpired,
  });
};

export const updateUserData = (body) => (dispatch) => {
  return AuthService.updateUserData(body).then(
    (data) => {
      dispatch({
        type: SET_USER_DATA,
        payload: data,
      });
      return Promise.resolve(data);
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export const forgetPassword = (body) => (dispatch) => {
  return AuthService.forgetPassword(body).then(
    () => {
      dispatch({
        type: SET_USER_DATA,
        payload: body,
      });
      return Promise.resolve(body);
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export const verifyOTP = (body) => (dispatch) => {
  return AuthService.verifyOTP(body).then(
    (data) => {
      if (data.isValid)
        dispatch({
          type: SET_USER_DATA,
          payload: body,
        });
      return Promise.resolve(data);
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export const resetPassword = (body) => (dispatch) => {
  return AuthService.resetPassword(body).then(
    (data) => {
      dispatch({
        type: SET_USER_DATA,
        payload: null,
      });
      return Promise.resolve(data);
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};
