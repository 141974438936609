import React, { lazy, Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import "./App.css";
import Spinner from "./App/Components/spinner";
import { checkTokenExpired, logout } from "./Store/actions/auth";
import { getAllCategory } from "./Store/actions/category";
import { navigate } from "./Store/actions/navigation";
const MainRouting = lazy(() => import("./mainRouting"));

function App() {
  const location = useLocation();
  const dispatch = useDispatch();
  const { isLoggedIn, isTokenExpired } = useSelector((state) => state.auth);
  const { currentPath } = useSelector((state) => state.navigation);
  const { allCategory } = useSelector((state) => state.category);

  useEffect(() => {
    dispatch(navigate(location.pathname));
    dispatch(checkTokenExpired());
    if (!allCategory)
      dispatch(getAllCategory())
        .then((data) => {
          return data;
        })
        .catch((err) => {
          return err;
        });
  }, [location, dispatch, allCategory]);

  useEffect(() => {
    if (isLoggedIn && isTokenExpired) {
      dispatch(logout())
        .then((res) => {
          return res;
        })
        .catch((err) => {
          return err;
        });
    }
  }, [dispatch, isTokenExpired, currentPath, isLoggedIn]);

  return (
    <div className="App">
      <Suspense fallback={<Spinner />}>
        <MainRouting />
      </Suspense>
    </div>
  );
}

export default App;
