import {
  ADD_AWARDS,
  ALL_AWARDS,
  AWARDS_BY_ID,
  DELETE_AWARDS,
  UPDATE_AWARDS,
} from "../actions/types.js";
const initialState = {
  allAwards: [],
  awardsDeleted: false,
  awardsById: {},
  awardsUpdated: false,
  awardsCreated: false,
};

export default function AwardsReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ALL_AWARDS:
      return {
        ...state,
        allAwards: payload,
      };
    case DELETE_AWARDS:
      return {
        ...state,
        awardsDeleted: payload,
      };
    case AWARDS_BY_ID:
      return {
        ...state,
        awardsById: payload,
      };
    case UPDATE_AWARDS:
      return {
        ...state,
        awardsUpdated: payload,
      };
    case ADD_AWARDS:
      return {
        ...state,
        awardsCreated: payload,
      };
    default:
      return state;
  }
}
