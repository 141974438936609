import {
  ADD_PROJECT,
  ALL_PROJECTS,
  DELETE_PROJECT,
  PROJECT_BY_ID,
  UPDATE_PROJECT,
} from "../actions/types.js";
const initialState = {
  allProjects: {},
  projectDeleted: false,
  projectById: {},
  projectUpdated: false,
  projectCreated: false,
};

export default function ProjectReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ALL_PROJECTS:
      return {
        ...state,
        allProjects: payload,
      };
    case DELETE_PROJECT:
      return {
        ...state,
        projectDeleted: payload,
      };
    case PROJECT_BY_ID:
      return {
        ...state,
        projectById: payload,
      };
    case UPDATE_PROJECT:
      return {
        ...state,
        projectUpdated: payload,
      };
    case ADD_PROJECT:
      return {
        ...state,
        projectCreated: payload,
      };
    default:
      return state;
  }
}
