export const BASE_URL = window.env.REACT_APP_BASE_URL;

export const ENDPOINTS = {
  LOGIN: "api/v1/auth/dashboard/login",
  LOGOUT: "api/v1/auth/logout",
  AUTH: "api/v1/auth/me",
  FORGET_PASSWORD: "api/v1/auth/forgot/password",
  RESET_PASSWORD: "api/v1/auth/reset/password",
  VERIFY_OTP: "api/v1/auth/verify-otp",
  PROJECTS: "api/v1/projects",
  PROJECT_BY_ID: "api/v1/projects/",
  CATEGORY: "api/v1/category",
  BTS: "api/v1/bts",
  CONTACT_US: "api/v1/contact",
  ABOUT_US: "api/v1/faqs",
  AWARDS: "api/v1/awards",
  TEAM: "api/v1/team",
};
export const NOT_PROTECTED_ENDPOINTS = [
  { url: ENDPOINTS.LOGIN, method: "post" },
  { url: ENDPOINTS.FORGET_PASSWORD, method: "post" },
  { url: ENDPOINTS.RESET_PASSWORD, method: "post" },
  { url: ENDPOINTS.VERIFY_OTP, method: "post" },
  { url: ENDPOINTS.PROJECTS, method: "get" },
  { url: ENDPOINTS.PROJECT_BY_ID, mthod: "get" },
  { url: ENDPOINTS.CATEGORY, method: "get" },
  { url: `${ENDPOINTS.CATEGORY}/mapped`, method: "get" },
  { url: ENDPOINTS.BTS, method: "get" },
  { url: ENDPOINTS.ABOUT_US, method: "get" },
  { url: ENDPOINTS.AWARDS, method: "get" },
  { url: ENDPOINTS.TEAM, method: "get" },
  { url: ENDPOINTS.CONTACT_US, method: "post" },
];
