import { ENDPOINTS } from "../../constantsAPI";
import instance from "./interceptor";

const listCategory = async (query) => {
  return instance
    .get(ENDPOINTS.CATEGORY, { params: query })
    .then((response) => {
      return response.data;
    });
};

const deleteCategory = async (id) => {
  return instance.delete(ENDPOINTS.CATEGORY + "/" + id).then((response) => {
    return response.data;
  });
};

const updateCategory = async (body, id) => {
  return instance
    .patch(ENDPOINTS.CATEGORY + "/" + id, body)
    .then((response) => {
      return response.data;
    });
};

const getCategoryById = async (id) => {
  return instance.get(ENDPOINTS.CATEGORY + "/" + id).then((response) => {
    return response.data;
  });
};
const createCategory = async (body) => {
  return instance.post(ENDPOINTS.CATEGORY, body).then((response) => {
    return response.data;
  });
};
const getCategoryMapped = async () => {
  return instance.get(`${ENDPOINTS.CATEGORY}/mapped`).then((response) => {
    return response.data;
  });
};
const obj = {
  listCategory,
  deleteCategory,
  updateCategory,
  getCategoryById,
  createCategory,
  getCategoryMapped,
};
export default obj;
