import {
  ADD_CONTACT_US,
  ALL_CONTACT_US,
  CONTACT_US_BY_ID,
  DELETE_CONTACT_US,
  UPDATE_CONTACT_US,
} from "../actions/types.js";
const initialState = {
  allContactUs: {},
  contactUsDeleted: false,
  contactUsById: {},
  contactUsUpdated: false,
  contactUsCreated: false,
};

export default function ContactUsReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ALL_CONTACT_US:
      return {
        ...state,
        allContactUs: payload,
      };
    case DELETE_CONTACT_US:
      return {
        ...state,
        contactUsDeleted: payload,
      };
    case CONTACT_US_BY_ID:
      return {
        ...state,
        contactUsById: payload,
      };
    case UPDATE_CONTACT_US:
      return {
        ...state,
        contactUsUpdated: payload,
      };
    case ADD_CONTACT_US:
      return {
        ...state,
        contactUsCreated: payload,
      };
    default:
      return state;
  }
}
