import {
  ADD_BTS,
  ALL_BTS,
  BTS_BY_ID,
  DELETE_BTS,
  UPDATE_BTS,
} from "../actions/types.js";
const initialState = {
  allBts: {},
  btsDeleted: false,
  btsById: {},
  btsUpdated: false,
  btsCreated: false,
};

export default function BtsReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ALL_BTS:
      return {
        ...state,
        allBts: payload,
      };
    case DELETE_BTS:
      return {
        ...state,
        btsDeleted: payload,
      };
    case BTS_BY_ID:
      return {
        ...state,
        btsById: payload,
      };
    case UPDATE_BTS:
      return {
        ...state,
        btsUpdated: payload,
      };
    case ADD_BTS:
      return {
        ...state,
        btsCreated: payload,
      };
    default:
      return state;
  }
}
