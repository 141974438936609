import {
  ABOUT_US_BY_ID,
  ADD_ABOUT_US,
  ALL_ABOUT_US,
  DELETE_ABOUT_US,
  UPDATE_ABOUT_US,
} from "../actions/types.js";
const initialState = {
  allAboutUs: [],
  aboutUsDeleted: false,
  aboutUsById: {},
  aboutUsUpdated: false,
  aboutUsCreated: false,
};

export default function AboutUsReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ALL_ABOUT_US:
      return {
        ...state,
        allAboutUs: payload,
      };
    case DELETE_ABOUT_US:
      return {
        ...state,
        aboutUsDeleted: payload,
      };
    case ABOUT_US_BY_ID:
      return {
        ...state,
        aboutUsById: payload,
      };
    case UPDATE_ABOUT_US:
      return {
        ...state,
        aboutUsUpdated: payload,
      };
    case ADD_ABOUT_US:
      return {
        ...state,
        aboutUsCreated: payload,
      };
    default:
      return state;
  }
}
