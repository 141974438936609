export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const GET_USER_DATA = "GET_USER_DATA";
export const IS_TOKEN_EXPIRED = "IS_TOKEN_EXPIRED";
export const SET_MESSAGE = "SET_MESSAGE";
export const SET_USER_DATA = "SET_USER_DATA";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const GET_SEARCH_DATA = "GET_SEARCH_DATA";
export const GET_USER_BY_ID = "GET_USER_BY_ID";
export const SET_USER_TOKEN = "SET_USER_TOKEN";
//Projects
export const ALL_PROJECTS = "ALL_PROJECTS";
export const PROJECT_BY_ID = "PROJECT_BY_ID";
export const DELETE_PROJECT = "DELETE_PROJECT";
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const ADD_PROJECT = "ADD_PROJECT";
//Category
export const ALL_CATEGORY = "ALL_CATEGORY";
export const CATEGORY_BY_ID = "CATEGORY_BY_ID";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const CATEGORY_MAPPED = "CATEGORY_MAPPED"
//BTS
export const ALL_BTS = "ALL_BTS";
export const BTS_BY_ID = "BTS_BY_ID";
export const DELETE_BTS = "DELETE_BTS";
export const UPDATE_BTS = "UPDATE_BTS";
export const ADD_BTS = "ADD_BTS";
//About-Us
export const ALL_ABOUT_US = "ALL_ABOUT_US";
export const ABOUT_US_BY_ID = "ABOUT_US_BY_ID";
export const DELETE_ABOUT_US = "DELETE_ABOUT_US";
export const UPDATE_ABOUT_US = "UPDATE_ABOUT_US";
export const ADD_ABOUT_US = "ADD_ABOUT_US";
//Awards
export const ALL_AWARDS = "ALL_AWARDS";
export const AWARDS_BY_ID = "AWARDS_BY_ID";
export const DELETE_AWARDS = "DELETE_AWARDS";
export const UPDATE_AWARDS = "UPDATE_AWARDS";
export const ADD_AWARDS = "ADD_AWARDS";
//Team
export const ALL_TEAM = "ALL_TEAM";
export const TEAM_BY_ID = "TEAM_BY_ID";
export const DELETE_TEAM = "DELETE_TEAM";
export const UPDATE_TEAM = "UPDATE_TEAM";
export const ADD_TEAM = "ADD_TEAM";
//Contact-Us
export const ALL_CONTACT_US = "ALL_CONTACT_US";
export const CONTACT_US_BY_ID = "CONTACT_US_BY_ID";
export const DELETE_CONTACT_US = "DELETE_CONTACT_US";
export const UPDATE_CONTACT_US = "UPDATE_CONTACT_US";
export const ADD_CONTACT_US = "ADD_CONTACT_US";
//Utilites
export const SET_SCREEN = "SET_SCREEN";
export const SET_SIDEBAR_COLLAPSE = "SET_SIDEBAR_COLLAPSE";
// Navigation
export const NAVIGATE = "NAVIGATE";
