import { toast } from "react-toastify";
import { ENDPOINTS, NOT_PROTECTED_ENDPOINTS } from "../../constantsAPI";
import { LOGOUT, SET_MESSAGE, SET_USER_TOKEN } from "../actions/types";
import { store } from "../index";
import { instance } from "./instance";

const isProtectedEndpoint = (config) => {
  return !NOT_PROTECTED_ENDPOINTS.some(
    (endpoint) =>
      config.url?.includes(endpoint?.url) && config.method === endpoint.method
  );
};
instance.interceptors.request.use(
  (config) => {
    if (isProtectedEndpoint(config) && store?.getState()?.auth?.user?.token) {
      const token = store.getState().auth.user.token;
      const refreshToken = store?.getState()?.auth?.user?.refreshToken;
      const isAccessTokenExpired = store?.getState()?.auth?.isTokenExpired;
      config.headers.Authorization = `Bearer ${token}`;
      if (isAccessTokenExpired) {
        config.headers.RefreshToken = `Bearer ${refreshToken}`;
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    if (response.headers["X-Token"] && response.headers["X-RefreshToken"]) {
      const payload = {
        token: response.headers["X-Token"],
        refreshToken: response.headers["X-RefreshToken"],
      };
      store.dispatch({
        type: SET_USER_TOKEN,
        payload: payload,
      });
    }
    if (response.status === 201) {
      toast.success(`Added successfully`);
      store.dispatch({
        type: SET_MESSAGE,
        payload: "AddSuccess",
        messageType: "Add Success",
      });
    }
    if (
      response.status === 200 &&
      response.config.method === "post" &&
      response.config.url === ENDPOINTS.LOGIN
    ) {
      toast.success(`Login successfully`);
      store.dispatch({
        type: SET_MESSAGE,
        payload: "Login successfull",
        messageType: "Login successfull",
      });
    }
    if (response.status === 200 && response.config.method === "patch") {
      toast.success(`Updated successfully`);
      store.dispatch({
        type: SET_MESSAGE,
        payload: "Update Success",
        messageType: "Update Success",
      });
    }
    if (response.status === 204 && response.config.method === "delete") {
      toast.success(`Deleted successfully`);
      store.dispatch({
        type: SET_MESSAGE,
        payload: "Delete Success",
        messageType: "Delete Success",
      });
    }
    if (
      response.status === 204 &&
      response.config.method === "post" &&
      response.config.url === ENDPOINTS.FORGET_PASSWORD
    ) {
      toast.success(`OTP sent successfully`);
      store.dispatch({
        type: SET_MESSAGE,
        payload: "OTP sent successfully",
        messageType: "OTP sent successfully",
      });
    }
    return response;
  },
  (error) => {
    if (error.response) {
      // console.log("RESPONSE ERROR", error.response);
      toast.error(`${error?.response?.data?.message}`);
      store.dispatch({
        type: SET_MESSAGE,
        payload: error.response.data,
        messageType: "Error",
      });
      if (error.response.status === 401) {
        store.dispatch({
          type: LOGOUT,
        });
      }
    } else if (error.request) {
      toast.error(`${error?.message}`);
      // console.log("The request was made but no response was received", error);
    } else {
      toast.error(`${error?.message}`);
      // console.log(
      //   "Something happened in setting up the request that triggered an Error",
      //   error.message
      // );
    }
    // console.log(error.config);

    return Promise.reject(error);
  }
);

export default instance;
