import {
  ADD_TEAM,
  ALL_TEAM,
  DELETE_TEAM,
  TEAM_BY_ID,
  UPDATE_TEAM,
} from "../actions/types.js";
const initialState = {
  allTeam: [],
  teamDeleted: false,
  teamById: {},
  teamUpdated: false,
  teamCreated: false,
};

export default function TeamReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ALL_TEAM:
      return {
        ...state,
        allTeam: payload,
      };
    case DELETE_TEAM:
      return {
        ...state,
        teamDeleted: payload,
      };
    case TEAM_BY_ID:
      return {
        ...state,
        teamById: payload,
      };
    case UPDATE_TEAM:
      return {
        ...state,
        teamUpdated: payload,
      };
    case ADD_TEAM:
      return {
        ...state,
        teamCreated: payload,
      };
    default:
      return state;
  }
}
