import { applyMiddleware, compose, createStore } from "redux";
// import { logger } from "redux-logger";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { thunk } from "redux-thunk";
import rootReducer from "./reducers/reducer";

const persistConfig = {
  key: "Quantum",
  version: "1.0",
  storage,
  blacklist: [
    "message",
    "projects",
    "bts",
    "contactUs",
    "aboutUs",
    "awards",
    "team",
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// const middleware = [thunk, logger];
const middleware = [thunk];

// Use Redux DevTools extension if available, otherwise use Redux compose
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(...middleware))
);

let persistor = persistStore(store);

export { persistor, store };
